import {Box, IconButton, makeStyles} from "@material-ui/core";
import clsx from 'clsx'
import SisLogoButton from "../Button/SisLogoButton";
import {useHistory, withRouter} from "react-router-dom";
import {HomeTwoTone} from "@material-ui/icons";
import AllCloudLogoButton from "../Button/AllCloudLogoButton";

const useStyles = makeStyles(theme => ({
  header: {
    height: '64px',
  },
  text: {
    color: theme.palette.primary.main,
    fontSize: '16px',
  },
  center: {
    paddingBottom: '4px',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-end',
      paddingRight: '8px',
    },
  },
  left: {
    width: '300px',
    display: 'flex',
  },
  right: {
    fontSize: '12px',
    width: '300px',
    paddingBottom: 12,
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
    },
  },
  icon: {
    color: theme.palette.secondary.main,
    fontSize: '36px',
  },
  iconContainer: {
    '&:hover $icon': {
      color: theme.palette.secondary.light,
    }
  },
  partneredWith : {
    paddingRight: 24,
    fontWeight: 600,
    color: '#999',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
}))

const Header = () => {
  const classes = useStyles();
  const history = useHistory();

  return <Box display='flex' justifyContent='space-between' className={classes.header}>
    <Box alignItems='center' justifyContent='flex-start' className={clsx(classes.text, classes.left)}>
      <IconButton className={classes.iconContainer} onClick={() => history.replace('/')}>
        <HomeTwoTone className={classes.icon}/>
      </IconButton>
    </Box>
    <Box display='flex' flexGrow={1} className={classes.center}>
      <SisLogoButton/>
    </Box>
    <Box justifyContent='flex-end' className={clsx(classes.text, classes.right)}>
      <Box display='flex' flexDirection='column' flexGrow={1}>
        <Box display='flex' justifyContent='flex-end' alignItems='flex-end' flexGrow={1} className={classes.partneredWith}>
          Partnered with
        </Box>
        <Box display='flex' justifyContent='flex-end' alignItems='center' flexGrow={1}>
          <AllCloudLogoButton/>
        </Box>
      </Box>
    </Box>
  </Box>

}

export default withRouter(Header)
