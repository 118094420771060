import React from "react";
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import {Backdrop, Container, CssBaseline, LinearProgress, makeStyles} from '@material-ui/core';
import {createTheme, ThemeProvider} from '@material-ui/core/styles';
import styles from './App.module.css';
import LandingPage from './component/LandingPage/LandingPage';
import TimeTrial from './component/TimeTrial/TimeTrial';
import TrialData from './component/TrialData/TrialData';
import OverallScore from "./component/OverallScore/OverallScore";
import LineAccuracy from "./component/LineAccuracy/LineAccuracy";
import DataQuery from "./component/DataQuery/DataQuery";

const theme = createTheme({
  palette: {
    primary: {
      main: '#163960',
      light: '#EDEBE8',
      dark: '#B3B1AF',
    },
    secondary: {
      main: '#F06D32',
      light: '#FAA333',
      dark: '#A11D23',
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
  },
})

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'transparent',
  },
  progress: {
    width: '100%',
    height: '6px',
    position: "absolute",
    top: 0,
    left: 0,
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}))

export interface BackDropCallback {
  (value: boolean): void
}

export interface AppProps {
  setBackdrop: BackDropCallback
}

function App() {
  const [open, setOpen] = React.useState(false);

  const classes = useStyles()

  const setBackdrop = (show: boolean) => {
      setOpen(show)
  }

  return (
      <ThemeProvider theme={theme}>
        <CssBaseline/>
        <Container className={styles.App} maxWidth={false}>
          <Router basename='/'>
            <Switch>
              <Route path={'/overall-score'} render={() => (<OverallScore setBackdrop={setBackdrop}/>)}/>
              <Route path={'/time-trial'} render={() => (<TimeTrial setBackdrop={setBackdrop}/>)}/>
              <Route path={'/line-accuracy'} render={() => (<LineAccuracy setBackdrop={setBackdrop}/>)}/>
              <Route path={'/trial-data/:trial?'} render={() => (<TrialData setBackdrop={setBackdrop}/>)}/>
              <Route path={'/data-query/:heat?'} render={() => (<DataQuery setBackdrop={setBackdrop}/>)}/>
              <Route path={'/'} component={LandingPage}/>
            </Switch>
          </Router>
        </Container>
        <div>
          <Backdrop className={classes.backdrop} open={open}>
            <LinearProgress color="primary" className={classes.progress}/>
          </Backdrop>
        </div>
      </ThemeProvider>
  );
}

export default App;
