import {polarisServer} from "../util/Utils";

export interface Ranking {
  trial: number,
  heat: number,
  driver: string
  overallScore: number
  overallRank: number
  heatRank: number
  totalTime: number
  reactionTime: number
  lap1Time: number
  lap1Trap: number
  lap2Time: number
  lap2Trap: number
  avgSpeedTrapTime: number
  avgLineAccuracy: number
  avgForwardTrigger: number
  avgReverseTrigger: number
  hits: number
}

export const fetchRankingsByHeat = (heat: number, completed: (rankings: Ranking[]) => void) => {
  fetch(polarisServer + '/rankingsbyheat/' + heat, {
    method: "GET",
    headers: {
      'Accept': '*/*',
      'Content-Type': 'application/json'
    },
    mode: 'cors'
  })
  .then(response => response.json())
  .then(data => {
    const rows: any[] = data
    if (rows && rows.length > 0) {
      const rankings: Ranking[] = []
      for (let r = 0; r < rows.length; r++) {
        const {Data: trialDetail} = rows[r]
        const {ScalarValue: svTrial} = trialDetail[0]
        const {ScalarValue: svDriver} = trialDetail[1]
        const {ScalarValue: svTotalTime} = trialDetail[8]
        const {ScalarValue: svOverallScore} = trialDetail[14]
        const {ScalarValue: svHeatRank} = trialDetail[15]
        const {ScalarValue: svOverallRank} = trialDetail[16]
        const {ScalarValue: svReactionTime} = trialDetail[2]
        const {ScalarValue: svLap1Time} = trialDetail[4]
        const {ScalarValue: svLap1Trap} = trialDetail[5]
        const {ScalarValue: svLap2Time} = trialDetail[6]
        const {ScalarValue: svLap2Trap} = trialDetail[7]
        const {ScalarValue: svAvgSpeedTrapTime} = trialDetail[9]
        const {ScalarValue: svAvgLineAccuracy} = trialDetail[10]
        const {ScalarValue: svHits} = trialDetail[11]
        const {ScalarValue: svAvgForwardTrigger} = trialDetail[12]
        const {ScalarValue: svAvgReverseTrigger} = trialDetail[13]

        const ranking: Ranking = {
          trial: parseInt(svTrial),
          heat: heat ? heat : -1,
          driver: svDriver,
          overallScore: parseFloat(svOverallScore),
          totalTime: parseFloat(svTotalTime),
          heatRank: parseInt(svHeatRank),
          overallRank: parseInt(svOverallRank),
          reactionTime: parseFloat(svReactionTime),
          lap1Time: parseFloat(svLap1Time),
          lap1Trap: parseFloat(svLap1Trap),
          lap2Time: parseFloat(svLap2Time),
          lap2Trap: parseFloat(svLap2Trap),
          avgSpeedTrapTime: parseFloat(svAvgSpeedTrapTime),
          avgLineAccuracy: parseFloat(svAvgLineAccuracy),
          avgForwardTrigger: parseFloat(svAvgForwardTrigger),
          avgReverseTrigger: parseFloat(svAvgReverseTrigger),
          hits: parseInt(svHits)
        }
        rankings.push(ranking)
      }
      completed(rankings)
    }
  })

}

export const fetchRankingByTrial = (trial: number, completed: (ranking?: Ranking) => void) => {
  fetch(polarisServer + '/rankings/' + trial, {
    method: "GET",
    headers: {
      'Accept': '*/*',
      'Content-Type': 'application/json'
    },
    mode: 'cors'
  })
  .then(response => response.json())
  .then(data => {
    const rows: any[] = data
    if (rows && rows.length > 0) {
      const {Data: rankingData} = rows[0]
      const {ScalarValue: svTrial} = rankingData[0]
      const {ScalarValue: svDriver} = rankingData[1]
      const {ScalarValue: svReactionTime} = rankingData[2]
      const {ScalarValue: svHeat} = rankingData[3]
      const {ScalarValue: svLap1Time} = rankingData[4]
      const {ScalarValue: svLap1Trap} = rankingData[5]
      const {ScalarValue: svLap2Time} = rankingData[6]
      const {ScalarValue: svLap2Trap} = rankingData[7]
      const {ScalarValue: svTotalTime} = rankingData[8]
      const {ScalarValue: svAvgLineAccuracy} = rankingData[9]
      const {ScalarValue: svHits} = rankingData[10]
      const {ScalarValue: svOverallScore} = rankingData[11]
      const {ScalarValue: svHeatRank} = rankingData[12]
      const {ScalarValue: svOverallRank} = rankingData[13]


      const ranking: Ranking = {
        trial: parseInt(svTrial),
        heat: parseInt(svHeat),
        driver: svDriver,
        overallScore: parseFloat(svOverallScore),
        overallRank: parseInt(svOverallRank),
        heatRank: parseInt(svHeatRank),
        totalTime: parseFloat(svTotalTime),
        reactionTime: parseFloat(svReactionTime),
        lap1Time: parseFloat(svLap1Time),
        lap1Trap: parseFloat(svLap1Trap),
        lap2Time: parseFloat(svLap2Time),
        lap2Trap: parseFloat(svLap2Trap),
        avgLineAccuracy: parseFloat(svAvgLineAccuracy),
        hits: parseInt(svHits),
        avgSpeedTrapTime: 0,
        avgForwardTrigger: 0,
        avgReverseTrigger: 0,
      }
      completed(ranking)
    } else {
      completed()
    }
  })
}

export const fetchCurrentTrial = (completed: (trial?: number) => void) => {
  fetch(polarisServer + '/currenttrial/', {
    method: "GET",
    headers: {
      'Accept': '*/*',
      'Content-Type': 'application/json'
    },
    mode: 'cors'
  })
  .then(response => response.json())
  .then(data => {
    const rows: any[] = data
    if (rows && rows.length > 0) {
      const {Data: trialData} = rows[0]
      const {ScalarValue: svTrial} = trialData[0]
      completed(parseInt(svTrial))
    } else {
      completed()
    }
  })
}