import {Box, makeStyles, Paper, Snackbar, withWidth} from '@material-ui/core'
import {formatFloat, polarisServer} from '../../util/Utils'
import PageLayout from "../Layout/PageLayout";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {AppProps} from "../../App";
import {TrialDataTiles} from "./TrialDataTiles";
import {TrialDataCharts} from "./TrailDataCharts";
import TrialDataTrialSelection from "./TrialDataTrialSelection";
import {fetchHeats, Heat} from "../../service/Heat";
import HeatSelection from "../common/HeatSelection";
import {fetchCurrentTrial, fetchRankingByTrial, fetchRankingsByHeat, Ranking} from "../../service/Trial";
import {Alert} from "../../util/Alert";

const CURRENT_TRIAL_POLL: number = 5000

const useStyles = makeStyles(theme => ({
  trialDataPage: {
    height: '100%',
  },
  title: {
    fontSize: '36px',
    fontWeight: 600,
    color: 'white',
    padding: 4,
    marginBottom: 12,
    backgroundColor: theme.palette.secondary.dark,
    border: 'none',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px'
    },
  },
  chartContainer: {
    height: '100%',
    paddingTop: '12px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '4px',
    },
  },
  waitingContainer: {
    fontSize: '36px',
    marginTop: '20vh',
    marginBottom: '80vh',
    fontWeight: 600,
    width: '50%',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
      width: '50%',
    },
  },
}))

interface RouteParams {
  trial: string
}

export interface Car {
  trial: number
  heat: number | undefined
  time: number
  tickTime: string
  lineAccuracy: number
  forwardTrigger: number
  reverseTrigger: number
  steeringPad: number
  maximumGs: number
}

interface TrialDataProps extends RouteComponentProps<RouteParams>, AppProps {
  width: string
}

const TrialData: React.FC<TrialDataProps> = ({setBackdrop, match, history, width}) => {
  const classes = useStyles();

  const breakpoint = width

  const trialFromPath: string | undefined = match.params.trial
  let pathTrialNumber: number | undefined = undefined

  let isCurrentTrial: boolean = false
  if (trialFromPath) {
    if (!isNaN(Number(trialFromPath))) {
      pathTrialNumber = parseInt(trialFromPath)
    } else if (trialFromPath === 'current-trial') {
      isCurrentTrial = true
    }
  }

  const timeoutContainer = useRef<NodeJS.Timeout | undefined>(undefined);
  const scrollPosition = useRef<any | undefined>(undefined);

  const [rankings, setRankings] = useState<Array<Ranking>>()
  const [ranking, setRanking] = useState<Ranking>()
  const [title, setTitle] = useState<string>('Trial Data')
  const [cars, setCars] = useState<Array<Car>>()
  const [heats, setHeats] = useState<Array<Heat>>()
  const [trial, setTrial] = useState<number | undefined>(pathTrialNumber)
  const [currentTrialEnabled] = useState<boolean>(isCurrentTrial)
  const [waitingForTrial, setWaitingForTrial] = useState<boolean>(isCurrentTrial)
  const [heat, setHeat] = useState<number | undefined>()
  const [initializing, setInitializing] = useState<boolean>(true)
  const [chartDisplay, setChartDisplay] = useState<string>('none')
  const [tilesDisplay, setTilesDisplay] = useState<string>('none')
  const [snackbarDisplay, setSnackbarDisplay] = useState<boolean>(false)
  const [snackbarMessage, setSnackbarMessage] = useState<string>()

  const fetchSeriesData = useCallback((currentTrial: boolean, trial: number, completed: (success: boolean) => void) => {
    const subPath: string = currentTrial ? '/cardata3/' : '/cardata2/'
    const path: string = polarisServer + subPath + trial
    fetch(path, {
      method: "GET",
      headers: {
        'Accept': '*/*',
        'Content-Type': 'application/json'
      },
      mode: 'cors'
    })
    .then(response => response.json())
    .then(data => {
      const rows: any[] = data
      if (rows && rows.length > 0) {
        const carDataValues: Car[] = []
        const arrayOffset = currentTrial ? 1 : 0
        let seconds = 0
        for (let r = 0; r < rows.length; r++) {
          const {Data: seriesData} = rows[r]
          const {ScalarValue: svTrial} = seriesData[0]

          let heat: number | undefined = undefined
          if (currentTrial) {
            const {ScalarValue: svHeat} = seriesData[1]
            heat = parseInt(svHeat)
          }
          const {ScalarValue: svTime} = seriesData[1 + arrayOffset]
          const {ScalarValue: svLineAccuracy} = seriesData[2 + arrayOffset]
          const {ScalarValue: svForwardTrigger} = seriesData[3 + arrayOffset]
          const {ScalarValue: svReverseTrigger} = seriesData[4 + arrayOffset]
          const {ScalarValue: svSteeringPad} = seriesData[5 + arrayOffset]
          const {ScalarValue: svMaximumGs} = seriesData[6 + arrayOffset]

          const tick = Math.trunc(seconds / 60).toString().padStart(2, '0') + ':' + (seconds % 60).toString().padStart(2, '0')
          seconds++

          const car: Car = {
            time: new Date(svTime).getTime(),
            tickTime: tick,
            trial: parseInt(svTrial),
            heat: heat,
            lineAccuracy: parseFloat(formatFloat(svLineAccuracy, 2)),
            forwardTrigger: parseFloat(formatFloat(svForwardTrigger, 2)),
            reverseTrigger: parseFloat(formatFloat(svReverseTrigger, 2)),
            maximumGs: parseFloat(formatFloat(svMaximumGs)),
            steeringPad: parseFloat(formatFloat(svSteeringPad))
          }
          carDataValues.push(car)
        }
        setCars(carDataValues)
        if (chartDisplay !== 'block') {
          setChartDisplay('block')
        }
        if (currentTrial) {
          const newTitle = 'Current Trial - Heat: ' + carDataValues[0].heat + ' Trial: ' + carDataValues[0].trial
          if (newTitle !== title) {
            setTitle(newTitle)
          }
        }
        completed(true)
      } else {
        completed(false)
      }
    })
  }, [chartDisplay, title])

  const resetTimer = () => {
    if (timeoutContainer.current) {
      const timerId = timeoutContainer.current as NodeJS.Timeout
      timeoutContainer.current = undefined
      clearTimeout(timerId)
    }
  }

  const fetchRankingsCallback = useCallback((noResultMessage: string) => {
    if (!trial && heat) {
      setHeats(undefined)
      if (initializing) {
        setInitializing(false)
        setBackdrop(true)
        fetchRankingsByHeat(heat, (fetchedRankings => {
          setRankings(fetchedRankings)
          setBackdrop(false)
          if (!fetchedRankings || fetchedRankings.length === 0) {
            setSnackbarMessage(noResultMessage);
            setSnackbarDisplay(true)
          }
        }))
      }
    }
  }, [initializing, heat, trial, setBackdrop])

  const fetchHeatsCallback = useCallback((noResultMessage: string) => {
    if (!heats) {
      if (initializing) {
        setInitializing(false)
        setBackdrop(true)
        fetchHeats((fetchedHeats => {
          setHeats(fetchedHeats)
          setBackdrop(false)
          if (!fetchedHeats || fetchedHeats.length === 0) {
            setSnackbarMessage(noResultMessage);
            setSnackbarDisplay(true)
          }
        }))
      }
    }
  }, [setBackdrop, initializing, heats])

  const fetchRankingCallback = useCallback((noResultsMessage: string) => {
        if (trial) {
          setRankings(undefined)
          if (initializing) {
            setBackdrop(true)
            setInitializing(false)
            fetchRankingByTrial(trial, ranking => {
              if (ranking) {
                setRanking(ranking)
                setTilesDisplay('block')
                fetchSeriesData(false, trial, (success: boolean) => {
                  if (!success) {
                    setSnackbarMessage(noResultsMessage)
                    setSnackbarDisplay(true)
                  }
                  setBackdrop(false)
                })
              } else {
                setSnackbarMessage(noResultsMessage)
                setSnackbarDisplay(true)
                setBackdrop(false)
              }
            })
          }
        }
      },
      [trial, initializing, setBackdrop, fetchSeriesData]
  )

  const fetchCurrentTrialSeriesCallback = useCallback((noResultsMessage: string) => {
    if (initializing && trial) {
      setInitializing(false)
      setBackdrop(true)
      fetchSeriesData(true, trial, (success: boolean) => {
        if (!success) {
          setSnackbarMessage(noResultsMessage)
          setSnackbarDisplay(true)
        }
        setBackdrop(false)
      })
    }
  }, [initializing, setBackdrop, trial, fetchSeriesData])

  const fetchCurrentTrialCallback = useCallback(() => {
    if (initializing) {
      setInitializing(false)
      setBackdrop(true)
      fetchCurrentTrial((currentTrial) => {
        if (currentTrial && currentTrial !== trial) {
          setTrial(currentTrial)
        }
        if (trial) {
          fetchSeriesData(true, trial, (success: boolean) => {
            setBackdrop(false)
          })
        } else {
          setWaitingForTrial(true)
          setInitializing(true)
        }
      })
    }
  }, [initializing, setBackdrop, fetchSeriesData, trial])

  const waitingForTrialCallback = useCallback(() => {
    if (initializing) {
      setInitializing(false)
      setBackdrop(true)
      fetchCurrentTrial((currentTrial) => {
        setBackdrop(false)
        if (currentTrial) {
          setWaitingForTrial(false)
          setTrial(currentTrial)
          setInitializing(true)
          if (!timeoutContainer.current) {
            timeoutContainer.current = setInterval(() => {
              setInitializing(true)
            }, CURRENT_TRIAL_POLL)
          }
        } else {
          if (!timeoutContainer.current) {
            timeoutContainer.current = setInterval(() => {
              setInitializing(true)
            }, CURRENT_TRIAL_POLL)
          }
        }
      })
    }
  }, [initializing, setBackdrop])

  const handleScroll = (event: any) => {
    scrollPosition.current = {
      element: event.target,
      scrollTop: event.target.scrollTop
    }
  };

  useEffect(() => {
    return () => {
      resetTimer()
    }
  }, [])

  useEffect(() => {
    if (currentTrialEnabled && !trial && waitingForTrial) {
      setTitle('Current Trial')
      waitingForTrialCallback()
    }

    if (currentTrialEnabled && trial && !waitingForTrial) {
      fetchCurrentTrialCallback()
    }

    if (!trial && !heats && !currentTrialEnabled) {
      fetchHeatsCallback('Error occurred retrieving heats information')
    }

    if (!trial && heat && !currentTrialEnabled) {
      fetchRankingsCallback('Error occurred retrieving trials for heat ' + heat + '.')
    }

    if (trial && !currentTrialEnabled) {
      fetchRankingCallback('Error retrieving trial data for trial ' + trial + '.')
    }

    /*
    if (trial && currentTrialEnabled) {
      fetchCurrentTrialSeriesCallback('Error retrieving trial data for trial ' + trial + '.')
    }

     */
  }, [currentTrialEnabled, fetchRankingCallback, fetchHeatsCallback, fetchRankingsCallback, heat, heats, trial, waitingForTrial, waitingForTrialCallback, fetchCurrentTrialCallback, fetchCurrentTrialSeriesCallback])

  const buildTrialData = () => {
    return <Box display='block' className={classes.trialDataPage}>
      <Box display='flex' flexGrow={1} justifyContent='center'
           className={classes.title}>{title}</Box>
      <Snackbar open={snackbarDisplay} anchorOrigin={{vertical: 'top', horizontal: 'center'}} autoHideDuration={7000}
                onClose={() => history.push('/')}>
        <Alert onClose={() => history.push('/')} severity="info">
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {waitingForTrial &&
      <Paper elevation={8} className={classes.waitingContainer}>
        <Box>
          Waiting for current trial track information...
        </Box>
      </Paper>
      }

      {(!heat && !trial && !currentTrialEnabled) &&
      <Box>
        <HeatSelection heatSelected={(heat: number) => {
          setHeat(heat)
          setInitializing(true)
        }} heats={heats}/>
      </Box>
      }

      {(!trial && heat && !currentTrialEnabled) &&
      <Box>
        <TrialDataTrialSelection trialSelected={(trial: number) => {
          setInitializing(true)
          setTrial(trial)
        }} rankings={rankings}/>
      </Box>
      }

      {trial &&
      <Box style={{overflow: 'auto', maxHeight: '80vh'}} onScroll={handleScroll} id='containers'>
        <Box display={tilesDisplay}>
          <TrialDataTiles ranking={ranking}/>
        </Box>
        <Box className={classes.chartContainer} display={chartDisplay}>
          <TrialDataCharts cars={cars} isCurrentTrail={isCurrentTrial} breakpoint={breakpoint} scrollPosition={scrollPosition}/>
        </Box>
      </Box>
      }
    </Box>
  }

  const WrappedTrialData = PageLayout(buildTrialData)

  return <WrappedTrialData/>
}

export default withRouter(withWidth()(TrialData))