import {DetailProps, PositionValue, PrimaryValue, RenderDetail, SecondaryValue, TotalValue} from '../Leaderboard/LeaderboardValue'
import {HeaderProps, PrimaryTitle, RenderHeader, SecondaryTitle} from '../Leaderboard/LeaderboardHeader'
import {Box} from '@material-ui/core'
import Leaderboard, {LEADERBOARD_POLL, leaderboardComponent, updateLeaderboard} from '../Leaderboard/Leaderboard'
import PageLayout from "../Layout/PageLayout";
import React, {useCallback, useEffect, useState} from "react";
import {formatFloat, polarisServer} from "../../util/Utils";
import {AppProps, BackDropCallback} from "../../App";

function createData(position: number, driver: string, total: string, averageLineAccuracy: string, averageSpeedTrap: string, reactionTime: string, hits: number, score: string, trial: number, heat: number) {
  return {position, driver, total, averageLineAccuracy, averageSpeedTrap, reactionTime, hits, score, trial, heat}
}

const renderHeader: RenderHeader = (row, breakpoint) => {
  const header: JSX.Element[] = []

  header.push(leaderboardComponent(<PrimaryTitle width='5%'/>))
  if (!/xs/.test(breakpoint)) {
    header.push(leaderboardComponent(<PrimaryTitle width='40%' title='Driver' additionalStyling={{paddingLeft: '12px'}}/>))
    header.push(leaderboardComponent(<SecondaryTitle align='center' title='Total Trial Time'/>))
    header.push(leaderboardComponent(<SecondaryTitle align='center' title='Average Speed Trap Time'/>))
    header.push(leaderboardComponent(<SecondaryTitle align='center' title='Average Line Accuracy'/>))
    header.push(leaderboardComponent(<SecondaryTitle align='center' title='Reaction Time'/>))
    header.push(leaderboardComponent(<SecondaryTitle align='center' title='Hits'/>))
    header.push(leaderboardComponent(<PrimaryTitle align='center' title='Overall Score'/>))
  } else {
    header.push(leaderboardComponent(<PrimaryTitle width='30%' title='Driver' additionalStyling={{paddingLeft: '12px'}}/>))
    header.push(leaderboardComponent(<SecondaryTitle align='center' title='Time'/>))
    header.push(leaderboardComponent(<SecondaryTitle align='center' title='Accuracy'/>))
    header.push(leaderboardComponent(<PrimaryTitle align='center' title='Score'/>))
  }

  return header
}

const renderDetail: RenderDetail = (row, breakpoint) => {
  const detail: JSX.Element[] = []

  detail.push(leaderboardComponent(<PositionValue data={row.position} justify='right'/>))
  detail.push(leaderboardComponent(<PrimaryValue data={row.driver}/>))
  detail.push(leaderboardComponent(<SecondaryValue data={row.total} justify='right'/>))
  if (!/xs/.test(breakpoint)) {
    detail.push(leaderboardComponent(<SecondaryValue data={row.averageSpeedTrap} justify='right'/>))
    detail.push(leaderboardComponent(<SecondaryValue data={row.averageLineAccuracy + '%'} justify='right'/>))
    detail.push(leaderboardComponent(<SecondaryValue data={row.reactionTime} justify='right'/>))
    detail.push(leaderboardComponent(<SecondaryValue data={row.hits} justify='right'/>))
  } else {
    detail.push(leaderboardComponent(<SecondaryValue data={row.averageLineAccuracy + '%'} justify='right'/>))
  }
  detail.push(leaderboardComponent(<TotalValue data={row.score} justify='right'/>))

  return detail
}

const OverallScore = (props: AppProps) => {

  const [rows, setRows] = useState<Array<any>>([])
  const [initializing, setInitializing] = useState<boolean>(true)

  const setBackdrop: BackDropCallback = props.setBackdrop

  const header: HeaderProps = {
    nRows: 1,
    renderHeader: renderHeader
  }

  const detail: DetailProps = {
    rows: rows,
    renderDetail: renderDetail
  }

  const fetchData = useCallback((completed?: () => void) => {
    fetch(polarisServer + '/overall', {
      method: "GET",
      headers: {
        'Accept': '*/*',
        'Content-Type': 'application/json'
      },
      mode: 'cors'
    })
    .then(response => response.json())
    .then(data => {
      const dataRows: any[] = data
      let position: number = 1
      const result: any[] = []
      dataRows.map((row: any) => {
        const {Data: data} = row

        const {ScalarValue: trial} = data[0]
        const {ScalarValue: driver} = data[1]
        const {ScalarValue: total} = data[2]
        const {ScalarValue: averageLineAccuracy} = data[3]
        const {ScalarValue: averageSpeedTrap} = data[4]
        const {ScalarValue: reactionTime} = data[5]
        const {ScalarValue: hits} = data[6]
        const {ScalarValue: score} = data[7]

        result.push(createData(position++, driver, formatFloat(total), formatFloat(averageLineAccuracy, 2), formatFloat(averageSpeedTrap), formatFloat(reactionTime), hits, formatFloat(score), trial, 0))
        return true
      })

      if (updateLeaderboard(rows, result)) {
        setRows(result)
      }
      if (completed) {
        completed()
      }
    })
  }, [rows])

  // noinspection DuplicatedCode
  useEffect(() => {
    if (initializing) {
      setInitializing(false)
      setBackdrop(true)
      fetchData(() => {
        setBackdrop(false)
      })
    }
    const interval = setInterval(() => {
      fetchData(() => {
        setBackdrop(false)
      })
    }, LEADERBOARD_POLL)

    return () => {
      clearInterval(interval)
    }
  }, [setBackdrop, initializing, fetchData])

  function buildLeaderboard() {
    return <Box display='block'>
      <Leaderboard title='Overall Score' detail={detail} header={header}/>
    </Box>
  }

  const WrappedOverallScore = PageLayout(buildLeaderboard)

  return <WrappedOverallScore/>
}

export default OverallScore