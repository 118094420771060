import React, {useEffect, useState} from "react";
import {Ranking} from "../../service/Trial";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withWidth
} from "@material-ui/core";
import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';
import {ChartParameters} from "./DataQueryChart";
import {formatFloat} from "../../util/Utils";
import {useHistory} from "react-router-dom";

interface RankingDetail {
  rankings: Ranking[]
  parameters: ChartParameters
  open: boolean
  width: string

  onClose(): void
}

const sortRankings = (rankings: any[], parameters: ChartParameters) => {
  if (parameters.descending) {
    return Array.from(rankings).sort((a, b) => (b[parameters.dataKey] > a[parameters.dataKey]) ? 1 : -1) as Ranking[]
  } else {
    return Array.from(rankings).sort((a, b) => (a[parameters.dataKey] > b[parameters.dataKey]) ? 1 : -1) as Ranking[]
  }
}

const useStyles = makeStyles(theme => ({
  title: {
    borderBottomStyle: 'solid',
    borderBottomWidth: 'thin'
  },
  data: {},
  container: {
    overflow: 'auto',
    maxHeight: '80vh'
  },
  header: {
    position: 'sticky',
    backgroundColor: theme.palette.primary.main,
    top: 0,
  },
  headerCell: {
    color: 'white',
  },
  valueRow: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.primary.light
    }
  },
  valueCell: {
    color: theme.palette.primary.main,
  },
  closeIcon: {
    color: '#555555',
    fontSize: '24px',
    '&:hover': {
      color: theme.palette.secondary.main,
      cursor: 'pointer',
    }
  },
  iconContainer: {
    padding: 4,
  },
}))

const formatValue = (value: number, parameters: ChartParameters) => {
  if (parameters.isInt) {
    return formatFloat(value, 0)
  }

  if (parameters.isPercent) {
    return formatFloat(value, 2) + '%'
  }

  return formatFloat(value, 3)
}

const QueryDataDetail: React.FC<RankingDetail> = ({rankings, parameters, open, onClose, width}) => {
  const [sortedRankings, setSortedRankings] = useState<Array<Ranking>>([])

  const history = useHistory()
  const classes = useStyles()

  useEffect(() => {
    if (!sortedRankings || sortedRankings.length === 0) {
      setSortedRankings(sortRankings(rankings, parameters))
    }
  }, [rankings, parameters, sortedRankings])

  const totalTimeParameters: ChartParameters = {
    dataKey: 'totalTime',
    title: 'Total Time'
  }

  const heatRankParameters: ChartParameters = {
    dataKey: 'heatRank',
    title: 'Heat Rank',
    isInt: true
  }

  const overallRankParameters: ChartParameters = {
    dataKey: 'overallRank',
    title: 'Overall Rank',
    isInt: true
  }

  const overallScoreParameters: ChartParameters = {
    dataKey: 'overallScore',
    title: 'Overall Score',
  }

  let cell2Parameters = totalTimeParameters
  let cell3Parameters = heatRankParameters
  let cell4Parameters = overallRankParameters

  if (parameters.dataKey === 'overallRank') {
    cell4Parameters = overallScoreParameters
  }

  if (parameters.dataKey === 'heatRank') {
    cell3Parameters = overallRankParameters
    cell4Parameters = overallScoreParameters
  }

  if (parameters.dataKey === 'totalTime') {
    cell2Parameters = heatRankParameters
    cell3Parameters = overallRankParameters
    cell4Parameters = overallScoreParameters
  }

  return <Dialog open={open} scroll='paper' maxWidth='lg'>
    <DialogTitle>
      <Box display='flex' className={classes.title}>
        <Box display='flex' flexGrow={1} justifyContent='flex-start'>{parameters.title}</Box>
        <Box display='flex' flexGrow={1} justifyContent='flex-end'>
          <CloseTwoToneIcon className={classes.closeIcon} onClick={() => onClose()}/>
        </Box>
      </Box>
    </DialogTitle>
    <DialogContent>
      <Box display='flex' className={classes.data}>
        <TableContainer className={classes.container}>
          <TableHead className={classes.header}>
            <TableRow>
              <TableCell className={classes.headerCell} align='center'>Trial</TableCell>
              <TableCell className={classes.headerCell} align='center'>Driver</TableCell>
              {!/xs/.test(width) &&
              <React.Fragment>
                <TableCell className={classes.headerCell} align='center'>{cell2Parameters.title}</TableCell>
                <TableCell className={classes.headerCell} align='center'>{cell3Parameters.title}</TableCell>
                <TableCell className={classes.headerCell} align='center'>{cell4Parameters.title}</TableCell>
              </React.Fragment>
              }
              <TableCell className={classes.headerCell} align='center'>{parameters.title}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRankings.map((ranking) => (
                <TableRow className={classes.valueRow} key={Math.random()} onClick={() => {history.push('/trial-data/' + ranking.trial)}}>
                  <TableCell className={classes.valueCell} align='right'>{ranking.trial}</TableCell>
                  <TableCell className={classes.valueCell}>{ranking.driver}</TableCell>
                  {!/xs/.test(width) &&
                  <React.Fragment>
                    <TableCell className={classes.valueCell} align='right'>{formatValue((ranking as any)[cell2Parameters.dataKey], cell2Parameters)}</TableCell>
                    <TableCell className={classes.valueCell} align='right'>{formatValue((ranking as any)[cell3Parameters.dataKey], cell3Parameters)}</TableCell>
                    <TableCell className={classes.valueCell} align='right'>{formatValue((ranking as any)[cell4Parameters.dataKey], cell4Parameters)}</TableCell>
                  </React.Fragment>
                  }
                  <TableCell className={classes.valueCell} align='right'>{formatValue((ranking as any)[parameters.dataKey], parameters)}</TableCell>
                </TableRow>
            ))}
          </TableBody>
        </TableContainer>
      </Box>
    </DialogContent>
  </Dialog>
}

export default withWidth()(QueryDataDetail)