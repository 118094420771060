import {Box, makeStyles, Paper} from '@material-ui/core'
import IconMenu from '../IconMenu/IconMenu'
import SisLogoButton from '../Button/SisLogoButton'
import AllCloudLogoButton from "../Button/AllCloudLogoButton";

const useStyles = makeStyles(theme => ({
  landingPage: {
    height: '100%',
    width: 'auto',
    overflow: 'auto'
  },
  headerRow: {
    height: '80px',
    padding: 4,
    borderBottomStyle: 'solid',
    borderBottom: 2,
    borderBottomColor: theme.palette.primary.light,
  },
  menuRow: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  footerRow: {
    height: '80px',
    padding: 4,
    borderTopStyle: 'solid',
    borderTop: 2,
    borderTopColor: theme.palette.primary.light,
  },
  partneredWith : {
    fontWeight: 600,
    color: '#999',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 12,
    },
  },
  allCloudLogo: {
    [theme.breakpoints.down('sm')]: {
      height: '100px',
    },
  }
}))

const LandingPage = () => {
  const classes = useStyles();

  return <Box display='flex' justifyContent='center' alignItems='center' className={classes.landingPage}>
    <Paper elevation={5}>
      <Box className={classes.headerRow} flexDirection='row'>
        <Box display='flex' flexGrow={1} height='100%' justifyContent='center'>
          <SisLogoButton/>
        </Box>
      </Box>
      <Box flexDirection='row' className={classes.menuRow}>
        <IconMenu/>
      </Box>
      <Box className={classes.footerRow} flexDirection='row'>
        <Box display='flex' flexGrow={1} height='100%' justifyContent='center'>
          <Box display='flex' flexDirection='column' flexGrow={1}>
            <Box display='flex' justifyContent='center' alignItems='flex-end' flexGrow={1} className={classes.partneredWith}>
              Partnered with
            </Box>
            <Box display='flex' justifyContent='center' flexGrow={1} className={classes.allCloudLogo}>
              <AllCloudLogoButton/>
            </Box>
          </Box>
        </Box>
      </Box>
    </Paper>
  </Box>
}

export default LandingPage