import {Box, makeStyles, Table, TableContainer, withWidth} from '@material-ui/core'
import {RouteComponentProps, withRouter} from 'react-router-dom'
import {DetailProps, LeaderBoardDetail} from './LeaderboardValue'
import {HeaderProps, LeaderboardHeader} from './LeaderboardHeader'
import React from "react";

export const LEADERBOARD_POLL: number = 10000

const useStyles = makeStyles(theme => ({
  leaderboard: {
    backgroundColor: 'transparent',
  },
  title: {
    fontSize: '36px',
    fontWeight: 600,
    color: 'white',
    padding: 4,
    marginBottom: 12,
    backgroundColor: theme.palette.secondary.dark,
    border: 'none',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px'
    },
  },
  container: {
    overflow: 'auto',
    maxHeight: '80vh'
  },
}))

interface LeaderBoardConfiguration extends RouteComponentProps {
  title: string
  width: string
  detail: DetailProps
  header: HeaderProps
}


export const updateLeaderboard = (existing: any[], update: any []) => {

  if (existing.length !== update.length) {
    return true
  }

  for (let x = 0; x < existing.length; x++) {
    for (let y = 0; y < update.length; y++) {
      if ((existing as any)['trial'] !== (update as any)['trial']) {
        return true
      }
    }
  }

  return false
}
export const leaderboardComponent = (jsxElement: JSX.Element) => {
  return <React.Fragment key={Math.random()}>
    {jsxElement}
  </React.Fragment>
}

export const Leaderboard: React.FC<LeaderBoardConfiguration> = (props) => {
  const classes = useStyles()
  const breakpoint = props.width

  return <Box display='block'>
    <Box display='block' className={classes.leaderboard}>
      <Box display='flex' flexGrow={1} justifyContent='center' className={classes.title}>{props.title}</Box>
      <TableContainer className={classes.container} id='container'>
        <Table>
          <LeaderboardHeader {...props.header} breakpoint={breakpoint}/>
          <LeaderBoardDetail {...props.detail} breakpoint={breakpoint}/>
        </Table>
      </TableContainer>
    </Box>
  </Box>
}

export default withRouter(withWidth()(Leaderboard))