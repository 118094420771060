import React, {useEffect, useState} from "react"
import {Box, makeStyles, useTheme} from "@material-ui/core"
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts"
import {chartStyles, formatFloat} from "../../util/Utils"
import {Ranking} from "../../service/Trial";
import {RouteComponentProps, withRouter} from "react-router-dom";
import ViewListTwoToneIcon from '@material-ui/icons/ViewListTwoTone'
import clsx from "clsx";
import QueryDataDetail from "./QueryDataDetail";

export interface ChartParameters {
  title: string,
  toolTip?: string,
  dataKey: string,
  isPercent?: boolean,
  isInt?: boolean | undefined
  descending?: boolean | undefined
}

interface Top20Ranking extends Ranking {
  rank: number
  axisLabel: string
}

const top20Rankings = (rankings: any[], parameters: ChartParameters) => {
  let sorted
  if (parameters.descending) {
    sorted = Array.from(rankings).sort((a, b) => (b[parameters.dataKey] > a[parameters.dataKey]) ? 1 : -1)
  } else {
    sorted = Array.from(rankings).sort((a, b) => (a[parameters.dataKey] > b[parameters.dataKey]) ? 1 : -1)
  }
  const top20 = sorted.slice(0, 20) as Ranking[]
  const result: Top20Ranking[] = []
  let index = 0
  for (let t: number = top20.length - 1; t >= 0; t--) {
    const ranking: Top20Ranking = {...top20[t], rank: index++, axisLabel: 'Trial: ' + top20[t].trial + ', ' + top20[t].driver}
    result.push(ranking)
  }
  return result.reverse()
}

export interface DataQueryChartProps extends RouteComponentProps {
  rankings: Ranking[],
  parameters: ChartParameters
}

const useStyles = makeStyles(theme => ({
  container: {
    width: 605,
    [theme.breakpoints.down('xs')]: {
      width: 300,
    },
    [theme.breakpoints.down('sm')]: {
      width: 310,
    },
  },
  categoryTitle: {
    paddingLeft: 4
  },
  icon: {
    color: '#555555',
    fontSize: '32px',
    '&:hover': {
      color: theme.palette.secondary.main,
      cursor: 'pointer',
    }
  },
  iconContainer: {
    padding: 4,
  },
}))

const DataQueryChart: React.FC<DataQueryChartProps> = ({rankings, parameters, history}) => {
  const [top20, setTop20] = useState<Array<Top20Ranking>>()
  const [minValue, setMinValue] = useState<number>(0)
  const [showDialog, setShowDialog] = useState<boolean>(false)

  const classes = useStyles()
  const chartClasses = chartStyles()
  const theme = useTheme();

  useEffect(() => {
    if (!top20) {
      const latest: Top20Ranking[] = top20Rankings(rankings, parameters)
      setTop20(latest)
      let min = Number.MAX_VALUE
      let max = Number.MIN_VALUE
      for (let t = 0; t < latest.length; t++) {
        const ranking = latest[t] as any
        const value = ranking[parameters.dataKey] as number
        if (value < min) {
          min = value
        }
        if (value > max) {
          max = value
        }
      }

      if (min >= 0) {
        min = min - ((max - min) / 20)
        if (min < 1) {
          min = 0
        }
        setMinValue(min)
      } else {
        min = min - ((max - min) / 20)
        if (min > -1) {
          min = 0
        }
        setMinValue(min)
      }
    }
  }, [rankings, parameters, top20])

  const valueFormatter = (value: number, parameters: ChartParameters) => {
    if (parameters.isInt) {
      return formatFloat(value, 0)
    }

    return formatFloat(value, 2)
  }

  return <Box display='flex' flexDirection='column' alignContent='center' className={classes.container}>
    <Box display='flex' className={chartClasses.chartTitle}>
      <Box display='flex' justifyContent='flex-start' flexGrow={1}
           className={clsx(chartClasses.chartTitle, classes.categoryTitle)}>{parameters.title}</Box>
      <Box display='flex' justifyContent='flex-end' flexGrow={1} className={classes.iconContainer}>
        <ViewListTwoToneIcon className={classes.icon} onClick={() => setShowDialog(true)}/>
      </Box>
    </Box>
    <Box display='flex' alignContent='center'>
      <ResponsiveContainer width='100%' height={350}>
        <BarChart data={top20} syncId={1} margin={{top: 4, right: 4, left: 15, bottom: 4}}>
          <Bar name={parameters.toolTip ? parameters.toolTip : parameters.title} dataKey={parameters.dataKey} type='number'
               fill={theme.palette.primary.main} cursor='pointer'
               onClick={(data) => history.push('/trial-data/' + data.trial)}/>
          <CartesianGrid stroke='#ccc'/>
          <YAxis dataKey={parameters.dataKey} type='number' scale='linear' domain={[minValue, 0]}
                 tickFormatter={value => valueFormatter(value, parameters)}/>
          <XAxis dataKey='axisLabel' type='category' tick={false}/>
          <Tooltip formatter={(value: number) => {
            if (parameters.isInt) {
              return value
            }
            if (parameters.isPercent) {
              return formatFloat(value.toString(), 2) + "%"
            }
            return formatFloat(value.toString())
          }} cursor={{stroke: theme.palette.secondary.dark, fill: 'transparent'}}/>
        </BarChart>
      </ResponsiveContainer>
    </Box>
    <QueryDataDetail rankings={rankings} parameters={parameters} open={showDialog} onClose={() => setShowDialog(false)}/>
  </Box>
}

export default withRouter(DataQueryChart)
