import {polarisServer} from "../util/Utils";
import moment from "moment";

export interface Heat {
  heat: number
  start: Date
  end: Date
  nTrials: number
}

export const fetchHeats = (completed: (heats: Heat[]) => void) => {
  fetch(polarisServer + '/heats', {
    method: "GET",
    headers: {
      'Accept': '*/*',
      'Content-Type': 'application/json'
    },
    mode: 'cors'
  })
  .then(response => response.json())
  .then(data => {
    const rows: any[] = data
    if (rows && rows.length > 0) {
      const heats: Heat[] = []
      for (let r = 0; r < rows.length; r++) {
        const {Data: heatDetail} = rows[r]
        const {ScalarValue: svHeat} = heatDetail[0]
        const {ScalarValue: svStart} = heatDetail[1]
        const {ScalarValue: svEnd} = heatDetail[2]
        const {ScalarValue: svTrials} = heatDetail[3]

        const heat: Heat = {
          heat: parseInt(svHeat),
          nTrials: svTrials,
          start: moment(svStart).toDate(),
          end: moment(svEnd).toDate(),
        }
        heats.push(heat)
      }
      completed(heats)
    } else {
      completed([])
    }
  })
}