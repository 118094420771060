import React, {useEffect} from "react";
import {Box, Paper, useTheme} from "@material-ui/core";
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {Car} from "./TrialData";
import {chartStyles, formatFloat} from "../../util/Utils";

interface TrialDataChartsProps {
  cars: Car[] | undefined
  isCurrentTrail: boolean | undefined
  breakpoint: string
  scrollPosition: any | undefined
}

const renderTimeLabel = (tickItem: string) => {
  return 'Time: ' + tickItem
}

export const TrialDataCharts: React.FC<TrialDataChartsProps> = ({cars, isCurrentTrail, breakpoint, scrollPosition}) => {
  const classes = chartStyles();
  const theme = useTheme();

  let chartHeight = 225
  if (isCurrentTrail && (!/xs|sm/.test(breakpoint))) {
    chartHeight = 340
  }

  useEffect(() => {
    const element = document.getElementById('containers')
    if (element && scrollPosition.current) {
      element.scrollTo(0, scrollPosition.current.scrollTop)
    }
  })

  return <Paper elevation={4}>
    <Box display='flex' className={classes.chartSection}>
      <Box display='flex' flexGrow={1} flexDirection='column'>
        <Box display='flex' justifyContent='center' className={classes.chartTitle}>Line Accuracy (Percentage)</Box>
        <Box display='flex' justifyContent='center'>
          <ResponsiveContainer width='90%' height={chartHeight}>
            <LineChart data={cars} margin={{ top: 4, right: 4, left: -20, bottom: 4 }} syncId={1}>
              <Line name='Accuracy' type='monotone' dataKey='lineAccuracy' stroke={theme.palette.primary.main} isAnimationActive={false}/>
              <CartesianGrid stroke='#ccc'/>
              <XAxis dataKey='tickTime' type='category'/>
              <YAxis/>
              <Tooltip labelFormatter={renderTimeLabel} formatter={(value: number) => formatFloat(value, 2) + '%'}/>
            </LineChart>
          </ResponsiveContainer>
        </Box>
      </Box>
      <Box display='flex' flexGrow={1} flexDirection='column'>
        <Box display='flex' justifyContent='center' className={classes.chartTitle}>Triggers (Percentage)</Box>
        <Box display='flex' justifyContent='center'>
          <ResponsiveContainer width='90%' height={chartHeight}>
            <LineChart data={cars} margin={{ top: 4, right: 4, left: -20, bottom: 4 }} syncId={1}>
              <Line name='Forward' type='monotone' dataKey='forwardTrigger' stroke={theme.palette.primary.main} isAnimationActive={false}/>
              <Line name='Reverse' type='monotone' dataKey='reverseTrigger' stroke={theme.palette.secondary.dark} isAnimationActive={false}/>
              <CartesianGrid stroke='#ccc'/>
              <XAxis dataKey='tickTime' type='category'/>
              <YAxis/>
              <Legend/>
              <Tooltip labelFormatter={renderTimeLabel} formatter={(value: number) => value ? formatFloat(value, 2) + '%' : '0.00%'}/>
            </LineChart>
          </ResponsiveContainer>
        </Box>
      </Box>
    </Box>
    <Box display='flex' className={classes.chartSection}>
      <Box display='flex' flexGrow={1} flexDirection='column'>
        <Box display='flex' justifyContent='center' className={classes.chartTitle}>G Forces</Box>
        <Box display='flex' justifyContent='center'>
          <ResponsiveContainer width='90%' height={chartHeight}>
            <LineChart data={cars} margin={{ top: 4, right: 4, left: -20, bottom: 4 }} syncId={1}>
              <Line name='G Force' type='monotone' dataKey='maximumGs' stroke={theme.palette.primary.main} isAnimationActive={false}/>
              <CartesianGrid stroke='#ccc'/>
              <XAxis dataKey='tickTime' type='category'/>
              <YAxis/>
              <Tooltip labelFormatter={renderTimeLabel}/>
            </LineChart>
          </ResponsiveContainer>
        </Box>
      </Box>
      <Box display='flex' flexGrow={1} flexDirection='column'>
        <Box display='flex' justifyContent='center' className={classes.chartTitle}>Steering Pad</Box>
        <Box display='flex' justifyContent='center'>
          <ResponsiveContainer width='90%' height={chartHeight}>
            <LineChart data={cars} margin={{ top: 4, right: 4, left: -20, bottom: 4 }} syncId={1}>
              <Line name='Steering Pad' type='monotone' dataKey='steeringPad' stroke={theme.palette.primary.main} isAnimationActive={false}/>
              <CartesianGrid stroke='#ccc'/>
              <XAxis dataKey='tickTime' type='category'/>
              <YAxis/>
              <Tooltip labelFormatter={renderTimeLabel}/>
            </LineChart>
          </ResponsiveContainer>
        </Box>
      </Box>
    </Box>
  </Paper>

}
