import {Button, makeStyles} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  logoButton: {
    width: '140px',
    height: '20px',
    [theme.breakpoints.down('sm')]: {
      width: '80px',
      height: '100&',
    },
    backgroundImage: 'url(image/allcloud-logo.png)',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    '&:hover': {
      backgroundColor: 'transparent',
    }
  },
}))

const SisLogoButton = () => {
  const classes = useStyles()

  return <Button className={classes.logoButton}
                 onClick={() => (window.location.href = 'https://allcloud.io/')}/>
}

export default SisLogoButton