import {AppProps} from "../../App";
import React, {useCallback, useEffect, useState} from "react";
import PageLayout from "../Layout/PageLayout";
import {Box, makeStyles, Snackbar} from "@material-ui/core";
import {fetchHeats, Heat} from "../../service/Heat";
import {fetchRankingsByHeat, Ranking} from "../../service/Trial";
import HeatSelection from "../common/HeatSelection";
import {chartStyles} from "../../util/Utils";
import DataQueryChart, {ChartParameters} from "./DataQueryChart";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {Alert} from "../../util/Alert";

interface RouteParams {
  heat: string
}

interface DataQueryProps extends RouteComponentProps<RouteParams>, AppProps {
}

const charts: ChartParameters[] = [
  {
    title: 'Overall Score',
    dataKey: 'overallScore',
    descending: true
  },
  {
    title: 'Overall Rank',
    dataKey: 'overallRank',
    isInt: true,
  },
  {
    title: 'Heat Rank',
    dataKey: 'heatRank',
    isInt: true,
  },
  {
    title: 'Total Time',
    dataKey: 'totalTime',
  },
  {
    title: 'Line Accuracy (Percentage)',
    toolTip: 'Line Accuracy',
    dataKey: 'avgLineAccuracy',
    isPercent: true,
    descending: true
  },
  {
    title: 'Reaction Time',
    dataKey: 'reactionTime',
  },
  {
    title: 'Hits',
    dataKey: 'hits',
    isInt: true
  },
  {
    title: 'Lap 1 Time',
    dataKey: 'lap1Time',
  },
  {
    title: 'Lap 1 Speed Trap',
    dataKey: 'lap1Trap',
  },
  {
    title: 'Lap 2 Time',
    dataKey: 'lap2Time',
  },
  {
    title: 'Lap 2 Speed Trap',
    dataKey: 'lap2Trap',
  },
  {
    title: 'Forward Trigger (Percentage)',
    toolTip: 'Forward Trigger',
    dataKey: 'avgForwardTrigger',
    isPercent: true,
    descending: true
  },
  {
    title: 'Reverse Trigger (Percentage)',
    toolTip: 'Reverse Trigger',
    dataKey: 'avgReverseTrigger',
    isPercent: true,
    descending: true
  },
]

const useStyles = makeStyles(theme => ({
  queryDataPage: {
    height: '100%',
  },
  title: {
    fontSize: '36px',
    fontWeight: 600,
    color: 'white',
    padding: 4,
    backgroundColor: theme.palette.secondary.dark,
    border: 'none',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px'
    },
  },
  heatInformation: {
    marginBottom: 8,
    backgroundColor: theme.palette.secondary.light,
    border: 'none',
  },
  heatLabel: {
    fontSize: '24px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px'
    },
  },
  chartContainer: {
    overflow: 'auto',
    maxHeight: '75vh',
  },
}))

const DataQuery: React.FC<DataQueryProps> = ({setBackdrop, match, history}) => {
  const [heats, setHeats] = useState<Array<Heat>>()
  const [rankings, setRankings] = useState<Array<Ranking>>()
  const [heat, setHeat] = useState<number | undefined>()
  const [initializing, setInitializing] = useState<boolean>(true)
  const [snackbarDisplay, setSnackbarDisplay] = useState<boolean>(false)
  const [snackbarMessage, setSnackbarMessage] = useState<string>()

  const classes = useStyles()
  const chartClasses = chartStyles()

  const heatFromPath = match.params.heat ? parseInt(match.params.heat) : undefined

  const fetchHeatsCallback = useCallback(() => {
    if (!heats) {
      if (initializing) {
        setInitializing(false)
        setBackdrop(true)
        fetchHeats((fetchedHeats => {
          setHeats(fetchedHeats)
          setBackdrop(false)
        }))
      }
    }
  }, [setBackdrop, initializing, heats])

  const fetchRankingsCallback = useCallback(() => {
    if (heat) {
      if (initializing) {
        setInitializing(false)
        setBackdrop(true)
        fetchRankingsByHeat(heat, (fetchedRankings => {
          setBackdrop(false)
          if (fetchedRankings && fetchedRankings.length) {
            setRankings(fetchedRankings)
          } else {
            setSnackbarMessage('Error occurred retrieving trials for heat ' + heat + '.')
            setSnackbarDisplay(true)
          }
        }))
      }
    }
  }, [setBackdrop, initializing, heat])

  useEffect(() => {
    if (heat !== heatFromPath) {
      setInitializing(true)
      setRankings(undefined)
      setHeat(heatFromPath)
      if (!heatFromPath) {
        setHeats(undefined)
      }
    }

    if (!heat && !heats) {
      if (!heatFromPath) {
        fetchHeatsCallback()
      } else {
        setHeat(heatFromPath)
      }
    }

    if (heat) {
      fetchRankingsCallback()
    }
  }, [heats, heat, fetchHeatsCallback, fetchRankingsCallback, heatFromPath])

  const buildQueryData = () => {
    return <Box display='block' className={classes.queryDataPage}>
      <Box display='flex' flexGrow={1} justifyContent='center' className={classes.title}>Data Query</Box>
      <Snackbar open={snackbarDisplay} anchorOrigin={{vertical: 'top', horizontal: 'center'}} autoHideDuration={7000}
                onClose={() => history.push('/')}>
        <Alert onClose={() => history.push('/')} severity="info">
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {!heat &&
      <Box>
        <HeatSelection heatSelected={(heat: number) => {
          const path: string = '/data-query/' + heat
          history.push(path)
        }} heats={heats}/>
      </Box>
      }

      {rankings &&
      <React.Fragment>
        <Box display='flex' flexGrow={1} justifyContent='center' className={classes.heatInformation}>
          <Box display='flex' className={classes.heatLabel}>Heat {heat}</Box>
        </Box>
        <Box className={classes.chartContainer}>
          <Box display='flex' flexWrap='wrap' alignContent='center' className={chartClasses.chartSection}>
            {charts?.map((parameters) => (
                <DataQueryChart rankings={rankings} parameters={parameters} key={Math.random()}/>
            ))}
          </Box>
        </Box>
      </React.Fragment>
      }
    </Box>
  }

  const WrappedDataQueryData = PageLayout(buildQueryData)

  return <WrappedDataQueryData/>
}

export default withRouter(DataQuery)