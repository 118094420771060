import React from "react";
import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import {formatHeatTime, selectionStyles} from "../../util/Utils";
import {Heat} from "../../service/Heat";

interface HeatsSelectionProps {
  heatSelected(heat: number): void
  heats: Heat[] | undefined
}

export const HeatSelection: React.FC<HeatsSelectionProps> = ({heats, heatSelected}) => {

  const classes = selectionStyles();

  return <Box display='flex' justifyContent='center'>
    <Box display='flex' className={classes.content} flexDirection='column'>
      <Box display='flex' className={classes.subTitle} flexGrow={1} justifyContent='center'>Select a Heat</Box>
      <Box display='flex' justifyContent='center'>
        <TableContainer className={classes.container}>
          <Table>
            <TableHead className={classes.header}>
              <TableRow className={classes.headingRow}>
                <TableCell className={classes.headingCell} align='center'>
                  <Box className={classes.title}>Heat</Box>
                </TableCell>
                <TableCell className={classes.headingCell} align='center'>
                  <Box className={classes.title}>Start Time</Box>
                </TableCell>
                <TableCell className={classes.headingCell} align='center'>
                  <Box className={classes.title}>End Time</Box>
                </TableCell>
                <TableCell className={classes.headingCell} align='center'>
                  <Box className={classes.title}>Trials</Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {heats?.map((heat) => (
                  <TableRow className={classes.valueRow} onClick={() => heatSelected(heat.heat)} key={Math.random()}>
                    <TableCell className={classes.dataCell}>
                      <Box display='flex' alignItems='center' justifyContent='flex-end'
                           className={classes.valueContainer}>{heat.heat}</Box>
                    </TableCell>
                    <TableCell className={classes.dataCell}>
                      <Box display='flex' alignItems='center' justifyContent='center'
                           className={classes.valueContainer}>{formatHeatTime(heat.start)}</Box>
                    </TableCell>
                    <TableCell className={classes.dataCell}>
                      <Box display='flex' alignItems='center' justifyContent='center'
                           className={classes.valueContainer}>{formatHeatTime(heat.end)}</Box>
                    </TableCell>
                    <TableCell className={classes.dataCell}>
                      <Box display='flex' alignItems='center' justifyContent='flex-end'
                           className={classes.valueContainer}>{heat.nTrials}</Box>
                    </TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  </Box>
}

export default HeatSelection