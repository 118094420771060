import React from "react";
import {Box, makeStyles, Paper} from "@material-ui/core";
import clsx from "clsx";
import {formatFloat} from "../../util/Utils";
import {Ranking} from "../../service/Trial";

interface TrialDataTilesProps {
  ranking: Ranking | undefined
}

const useStyles = makeStyles(theme => ({
  tileContainer: {
    width: '80%',
    margin: 'auto',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  tile: {
    backgroundColor: theme.palette.primary.main,
    margin: 8,
  },
  tileTitle: {
    color: 'white',
    fontSize: '16px',
    fontWeight: 500,
    marginLeft: 4,
    marginRight: 4,
    padding: 8,
    borderBottomColor: 'white',
    borderBottomStyle: 'solid',
  },
  tileRow: {
    color: 'white',
    fontSize: '14px',
    fontWeight: 400,
    marginLeft: 8,
    marginRight: 8,
    padding: 8,
  },
  tileBorder: {
    borderBottomColor: 'white',
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
  },
  tileRowTitle: {
    width: '50%',
  },
}))

export const TrialDataTiles: React.FC<TrialDataTilesProps> = ({ranking}) => {
  const classes = useStyles();

  return <Paper elevation={4}>
    <Box display='flex' className={classes.tileContainer} justifyContent='center'>
      <Box className={classes.tile} display='flex' flexDirection='column' flexGrow={1}>
        <Box display='flex' justifyContent='center' className={classes.tileTitle}>Race</Box>
        <Box display='flex' flexDirection='column'>
          <Box display='flex' className={clsx(classes.tileRow, classes.tileBorder)}>
            <Box display='flex' justifyContent='flex-start' className={classes.tileRowTitle}>Driver:</Box>
            <Box display='flex' justifyContent='flex-start'>{ranking?.driver}</Box>
          </Box>
          <Box display='flex' className={clsx(classes.tileRow, classes.tileBorder)}>
            <Box display='flex' justifyContent='flex-start' className={classes.tileRowTitle}>Heat:</Box>
            <Box display='flex' justifyContent='flex-start'>{ranking?.heat}</Box>
          </Box>
          <Box display='flex' className={classes.tileRow}>
            <Box display='flex' justifyContent='flex-start' className={classes.tileRowTitle}>Trial:</Box>
            <Box display='flex' justifyContent='flex-start'>{ranking?.trial}</Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.tile} display='flex' flexDirection='column' flexGrow={1}>
        <Box display='flex' justifyContent='center' className={classes.tileTitle}>Race Details</Box>
        <Box display='flex' flexDirection='column'>
          <Box display='flex' className={clsx(classes.tileRow, classes.tileBorder)}>
            <Box display='flex' justifyContent='flex-start' className={classes.tileRowTitle}>Total Time:</Box>
            <Box display='flex' justifyContent='flex-start'>{formatFloat(ranking?.totalTime)}</Box>
          </Box>
          <Box display='flex' className={clsx(classes.tileRow, classes.tileBorder)}>
            <Box display='flex' justifyContent='flex-start' className={classes.tileRowTitle}>Line Accuracy:</Box>
            <Box display='flex' justifyContent='flex-start'>{formatFloat(ranking?.avgLineAccuracy, 2) + '%'}</Box>
          </Box>
          <Box display='flex' className={clsx(classes.tileRow, classes.tileBorder)}>
            <Box display='flex' justifyContent='flex-start' className={classes.tileRowTitle}>Reaction Time:</Box>
            <Box display='flex' justifyContent='flex-start'>{formatFloat(ranking?.reactionTime)}</Box>
          </Box>
          <Box display='flex' className={classes.tileRow}>
            <Box display='flex' justifyContent='flex-start' className={classes.tileRowTitle}>Hits:</Box>
            <Box display='flex' justifyContent='flex-start'>{ranking?.hits}</Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.tile} display='flex' flexDirection='column' flexGrow={1}>
        <Box display='flex' justifyContent='center' className={classes.tileTitle}>Lap Times</Box>
        <Box display='flex' flexDirection='column'>
          <Box display='flex' className={clsx(classes.tileRow, classes.tileBorder)}>
            <Box display='flex' justifyContent='flex-start' className={classes.tileRowTitle}>Lap 1:</Box>
            <Box display='flex' justifyContent='flex-start'>{formatFloat(ranking?.lap1Time)}</Box>
          </Box>
          <Box display='flex' className={clsx(classes.tileRow, classes.tileBorder)}>
            <Box display='flex' justifyContent='flex-start' className={classes.tileRowTitle}>Lap 1 Trap:</Box>
            <Box display='flex' justifyContent='flex-start'>{formatFloat(ranking?.lap1Trap)}</Box>
          </Box>
          <Box display='flex' className={clsx(classes.tileRow, classes.tileBorder)}>
            <Box display='flex' justifyContent='flex-start' className={classes.tileRowTitle}>Lap 2:</Box>
            <Box display='flex' justifyContent='flex-start'>{formatFloat(ranking?.lap2Time)}</Box>
          </Box>
          <Box display='flex' className={classes.tileRow}>
            <Box display='flex' justifyContent='flex-start' className={classes.tileRowTitle}>Lap 2 Trap:</Box>
            <Box display='flex' justifyContent='flex-start'>{formatFloat(ranking?.lap2Trap)}</Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.tile} display='flex' flexDirection='column' flexGrow={1}>
        <Box display='flex' justifyContent='center' className={classes.tileTitle}>Ranking</Box>
        <Box display='flex' flexDirection='column'>
          <Box display='flex' className={clsx(classes.tileRow, classes.tileBorder)}>
            <Box display='flex' justifyContent='flex-start' className={classes.tileRowTitle}>Overall Rank:</Box>
            <Box display='flex' justifyContent='flex-start'>{ranking?.overallRank}</Box>
          </Box>
          <Box display='flex' className={clsx(classes.tileRow, classes.tileBorder)}>
            <Box display='flex' justifyContent='flex-start' className={classes.tileRowTitle}>Heat Rank:</Box>
            <Box display='flex' justifyContent='flex-start'>{ranking?.heatRank}</Box>
          </Box>
          <Box display='flex' className={classes.tileRow}>
            <Box display='flex' justifyContent='flex-start' className={classes.tileRowTitle}>Overall Score:</Box>
            <Box display='flex' justifyContent='flex-start'>{formatFloat(ranking?.overallScore)}</Box>
          </Box>
        </Box>
      </Box>
    </Box>
  </Paper>

}