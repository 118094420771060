import {makeStyles} from "@material-ui/core";

export const polarisServer: string = 'https://backend.polarisdata.net'

export const formatFloat = (value: number | string | undefined, precision: number = 3) => {
  if (value) {
    const parsedValue: string = parseFloat(value.toString()).toFixed(precision)
    if (parsedValue) {
      return parsedValue
    }
    return '0.0'
  } else {
    return ''
  }
}

export const formatHeatTime = (dateTime: Date) => {
  console.log("***** Heat Time: " + dateTime)

  const month = (dateTime.getMonth() + 1).toString().padStart(2, '0')
  const date = (dateTime.getDate()).toString().padStart(2, '0')
  const hours = (dateTime.getHours()).toString().padStart(2, '0')
  const minutes = (dateTime.getMinutes()).toString().padStart(2, '0')
  return month + '/' + date + ' - ' + hours + ':' + minutes
}

export const selectionStyles = makeStyles(theme => ({
  container: {
    overflow: 'auto',
    maxHeight: '72vh',
    [theme.breakpoints.down('xs')]: {
      maxHeight: '65vh',
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: '40vh',
    },
  },
  header: {
    position: 'sticky',
    top: 0,
  },
  content: {
    width: '80%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  title: {
    fontSize: '24px',
    fontWeight: 600,
    color: 'black',
    border: 'none',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      fontWeight: 400,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
      fontWeight: 400,
    },
  },
  subTitle: {
    fontSize: '28px',
    fontWeight: 600,
    padding: 6,
    backgroundColor: theme.palette.secondary.light,
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },
  headingRow: {
    padding: 4,
    backgroundColor: theme.palette.primary.dark,
    border: 'none'
  },
  headingCell: {
    padding: 4,
    paddingLeft: 12,
    border: 'none',
  },
  dataCell: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 12,
    paddingBottom: 0,
    border: 0,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      whiteSpace: 'wrap',
      paddingTop: 6,
      maxWidth: '100px',
    },
  },
  valueContainer: {
    height: '36px',
    borderLeftStyle: 'solid',
    borderLeftWidth: 2,
    paddingLeft: 12,
    paddingRight: 12,
    fontSize: '18px',
    fontWeight: 500,
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      height: '32px',
      fontSize: '16px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '30px',
      fontSize: '11px',
      fontWeight: 400,
      paddingLeft: 2,
      paddingRight: 2,
    }
  },
  valueRow: {
    background: 'transparent',
    '&:hover': {
      cursor: 'pointer',
    }
  },
}))

export const chartStyles = makeStyles(theme => ({
  chartSection: {
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  chartTitle: {
    color: 'black',
    fontSize: '16px',
    fontWeight: 500,
    paddingTop: 4,
    paddingBottom: 4,
    margin: 4,
    backgroundColor: theme.palette.primary.dark,
  }
}))


