import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import {formatFloat, selectionStyles} from "../../util/Utils";
import React from "react";
import {Ranking} from "../../service/Trial";

interface TrialDataTrialsSelectionProps {
  trialSelected(trial: number): void

  rankings: Ranking[] | undefined
}

export const TrialDataTrialSelection: React.FC<TrialDataTrialsSelectionProps> = ({rankings, trialSelected}) => {

  const classes = selectionStyles()

  return <Box display='flex' justifyContent='center'>
    <Box display='flex' className={classes.content} flexDirection='column'>
      <Box display='flex' className={classes.subTitle} flexGrow={1} justifyContent='center'>Select a Trial</Box>
      <Box display='flex' justifyContent='center'>
        <TableContainer className={classes.container}>
          <Table>
            <TableHead className={classes.header}>
              <TableRow className={classes.headingRow}>
                <TableCell className={classes.headingCell} align='center'>
                  <Box className={classes.title}>Trial</Box>
                </TableCell>
                <TableCell className={classes.headingCell} align='center'>
                  <Box className={classes.title}>Driver</Box>
                </TableCell>
                <TableCell className={classes.headingCell} align='center'>
                  <Box className={classes.title}>Total Time</Box>
                </TableCell>
                <TableCell className={classes.headingCell} align='center'>
                  <Box className={classes.title}>Heat Rank</Box>
                </TableCell>
                <TableCell className={classes.headingCell} align='center'>
                  <Box className={classes.title}>Overall Rank</Box>
                </TableCell>
                <TableCell className={classes.headingCell} align='center'>
                  <Box className={classes.title}>Overall Score</Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rankings?.map((ranking) => (
                  <TableRow className={classes.valueRow} onClick={() => trialSelected(ranking.trial)} key={Math.random()}>
                    <TableCell className={classes.dataCell}>
                      <Box display='flex' alignItems='center' justifyContent='flex-end'
                           className={classes.valueContainer}>{ranking.trial}</Box>
                    </TableCell>
                    <TableCell className={classes.dataCell}>
                      <Box display='flex' alignItems='center' justifyContent='flex-start'
                           className={classes.valueContainer}>{ranking.driver}</Box>
                    </TableCell>
                    <TableCell className={classes.dataCell}>
                      <Box display='flex' alignItems='center' justifyContent='flex-end'
                           className={classes.valueContainer}>{formatFloat(ranking.totalTime)}</Box>
                    </TableCell>
                    <TableCell className={classes.dataCell}>
                      <Box display='flex' alignItems='center' justifyContent='flex-end'
                           className={classes.valueContainer}>{ranking.heatRank}</Box>
                    </TableCell>
                    <TableCell className={classes.dataCell}>
                      <Box display='flex' alignItems='center' justifyContent='flex-end'
                           className={classes.valueContainer}>{ranking.overallRank}</Box>
                    </TableCell>
                    <TableCell className={classes.dataCell}>
                      <Box display='flex' alignItems='center' justifyContent='flex-end'
                           className={classes.valueContainer}>{formatFloat(ranking.overallScore)}</Box>
                    </TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  </Box>
}

export default TrialDataTrialSelection
